<template>
  <div>
    <GenericTable
      ref="lista"
      name="material médico"
      title="Materiais Médicos"
      add-route="materialMedicoAdicionar"
      edit-route="materialMedicoEditar"
      rastreabilidadeRoute="materialRastrear"
      :cols="['Material', 'Quantidade de Itens','Nome do Cirurgião','CRM']"
      :cols-name="['descricao', 'quantidade_itens','nome_medico','crm']"
      :cols-map="i => [i.descricao, i.quantidade_itens, i.medico.nome, i.medico.crm]"
      :route="materialRoute"
      :permissionsToWrite="['rw_cadastro_rapido']"
      :filters="filters"
      :fixedFilters="fixedFilters"
      :hasPagination="true"
      @clear-filters="clearFilters"
      idKey="id_material"
    >
      <template #filters>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>Descrição</label>
              <b-form-input
                v-model="filters.descricao"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>QR Code</label>
              <b-form-input
                v-model="filters.code"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
              <label>ID</label>
              <b-form-input
                v-model="filters.id_material"
                autocomplete="off"
                class="invision-input sm"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group>
            <label>Nome do médico</label>
              <GenericSelect
                ref="id_medico"
                name="id_medico"
                v-model="filters.id_medico"
                route="medico"
                labelKey="nome"
              />
            </b-form-group>
          </b-col>
        </b-row>
       </template>
    </GenericTable>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import GenericSelect from '../../../components/Form/GenericSelect';

export default {
  components: {
    GenericSelect,
  },
  data() {
    return {
      materialRoute: 'material',
      filters: {
        descricao: '',
        code: '',
        id_material: '',
        nomeMedico: '',
      },
      fixedFilters: {
        id_tipo_material: 2,
      },
    };
  },
  computed: {
    ...mapState(['previousRoute', 'filtersStored']),
  },
  created() {
    if (this.filtersStored.materialMedico) {
      if (['materialRastrear', 'materialMedicoEditar'].includes(this.previousRoute.name)) {
        this.filters = { ...this.filtersStored.materialMedico };
      } else {
        this.$store.commit('setFiltersStored', {});
      }
    }
  },
  methods: {
    clearFilters() {
      this.filters.descricao = '';
      this.filters.code = '';
      this.filters.id_material = '';
      this.filters.nomeMedico = '';
    },
  },
};
</script>
